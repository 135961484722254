import React, { Component } from "react"

const settings = require("../../gatsby-config").siteMetadata.settings

class GoogleSearchBox extends Component {
  constructor(props) {
    super(props)
    this.gcseElement = React.createElement("gcse:search", null, null)

    this.componentDidMount = () => {
      const cx = settings.GOOGLE_CSE_ID
      const gcse = document.createElement("script")
      gcse.type = "text/javascript"
      gcse.async = true
      gcse.src = `https://cse.google.com/cse.js?cx=${cx}`
      const s = document.getElementsByTagName("script")[0]
      s.parentNode.insertBefore(gcse, s)
    }
  }

  render() {
    return (
      <div
        className="google-search-box"
        style={{
          width: `100%`,
          maxWidth: `960px`,
          marginLeft: `auto`,
          marginRight: `auto`
        }}
      >
        {this.gcseElement}
      </div>
    )
  }
}

export default GoogleSearchBox
