/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Header from "./header"
import Footer from "./footer"
import GoogleSearchBox from "./google-search"
import "../styles/bootstrap.min.css"
import "../styles/font-awesome.min.css"
import "../styles/layout.css"
import "../styles/base.css"
import "../styles/gt.css"
import "../styles/custom.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              title
              link
            }
          }
        }
      }
    `}
    render={data => (
      <Container fluid="true" className="noPadding">
        <Header
          siteTitle={data.site.siteMetadata.title}
          menuLinks={data.site.siteMetadata.menuLinks}
          id="gt-header"
        />
        <Row id="page">
          <Col role="main" id="main">
            <GoogleSearchBox />
            {children}
          </Col>
        </Row>
        <Footer />
      </Container>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
