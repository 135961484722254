import PropTypes from "prop-types"
import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import ctnGoldLogo from "../images/creating_the_next_gold.svg"
import gtriLogo from "../images/logos/gtri-logo.png"
import Navigation from "./navigation"
import "../styles/header.scss"

const Header = ({ siteTitle, menuLinks }) => (
  <header id="gt-header">
    <div id="gt-header-bg-accent-row">
      <Container>
        <div id="striped-accent-bar"></div>
      </Container>
    </div>
    <div id="top-header" className="top-background-wrapper">
      <Container>
        <Row>
          <Col
            className="col-6 col-sm-4 col-md-4 col-lg-3 p-0 d-flex flex-row"
            id="angle-top-wrapper"
          >
            <div id="angle-top-goldblock"></div>
            <div className="top-background" />
            <div className="top-background-angle" />
          </Col>
          <Col className="p-0 d-flex flex-row justify-content-end">
            <div id="ctn-angle" className="d-none d-md-block"></div>
            <div id="ctn" className="d-none d-md-block">
              <img src={ctnGoldLogo} alt="Creating the Next" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <div id="middle-header" className="middle-background-wrapper">
      <div className="container">
        <Row className="middle-background">
          {/* GTRI Logo  */}
          <Col
            className="col-6 col-sm-4 col-md-4 col-lg-3 p-0 d-flex flex-row"
            id="logo-wrapper"
          >
            <div id="logo-goldblock">
              <div className="gtri-logo">
                <a
                  href="https://gtri.gatech.edu"
                  title="Georgia Tech Research Institute"
                >
                  {" "}
                  <img
                    src={gtriLogo}
                    alt="Georgia Tech Research Institute"
                  />{" "}
                </a>
              </div>
            </div>
            <div id="angle-after-logo"></div>
          </Col>

          {/* <!--  GT Hamburger --> */}
          <Col className="col-2 order-md-1 ml-auto">
            {/* Menu icon goes here */}
          </Col>

          {/* Site Information */}
          <Col
            className="col-12 col-sm-12 col-md-8 col-lg-9 p-0 d-flex flex-row align-items-center"
            id="site-title"
          >
            <div className="site-title-multiple">
              <h2 className="site-name two-line" rel="home">
                <a href="/">{siteTitle}</a>
              </h2>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div id="bottom-header" className="middle-background-wrapper">
      <Navigation menuLinks={menuLinks} />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
