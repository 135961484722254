import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

const Navigation = ({ menuLinks }) => (
  <Navbar expand="md" id="Navbar" className="container">
    <div className="row col">
      <div
        id="page-navigation"
        className="main-nav mr-auto"
        aria-label="main navigation"
      >
        <nav>
          <Nav as="ul" activeKey>
            {menuLinks.map((link, key) => (
              <Nav.Item as="li" key={key}>
                <Nav.Link href={link.link}>{link.title}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </nav>
      </div>
    </div>
  </Navbar>
)

export default Navigation
