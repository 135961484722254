module.exports = {
  siteMetadata: {
    title: `GTRI Historical Archive`,
    description: `The history of GTRI is one filled with intrigue, innovation and impact. Learn about GTRI's exciting past, present and future.`,
    keywords: `GTRI, research, Georgia Tech Research Institute, Georgia Tech, history, archive`,
    siteUrl: `https://history.gtri.gatech.edu`,
    author: `@gtri`,
    menuLinks: [
      {
        title: "Home",
        link: "/"
      },
      {
        title: "Our History",
        link: "/our-history"
      },
      {
        title: "Innovations",
        link: "/innovations"
      },
      {
        title: "Our Forebearers",
        link: "/our-forebearers"
      },
      {
        title: "Archives",
        link: "/archives"
      }
    ],
    settings: {
      MEDIA_PER_PAGE: 16,
      PUBLICATIONS_PER_PAGE: 10,
      GOOGLE_CSE_ID: "004222601262857650832:gb9xz2pcuqm"
    },
    publications: {
      // This associates the publication's directory with a friendly title which will be displayed on the listing page.
      "annual-reports": {
        title: "Annual Reports"
      },
      connector: {
        title: "GTRI Connector"
      },
      "ees-notes-reports": {
        title: "EES Notes"
      },
      insider: {
        title: "GTRInsider"
      },
      "news-releases": {
        title: "GTRI News Releases"
      },
      "other-publications": {
        title: "Other Publications"
      },
      "research-engineer": {
        title: "The Research Engineer"
      },
      "station-news": {
        title: "Station News"
      }
    }
  },
  plugins: [
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        // You can add multiple tracking ids and a pageview event will be fired for all of them.
        trackingIds: [
          "G-62WD3J0V0V" // Google Analytics / GA
        ],
        // This config will be shared across all trackingIds
        gtagConfig: {
          optimize_id: "GTM-W3LJGHS",
          anonymize_ip: true,
          cookie_expires: 0
        },
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: false,
          // Setting this parameter is also optional
          respectDNT: true
          // Avoids sending pageview hits from custom paths
        }
      }
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `books`,
        path: `${__dirname}/src/markdown/books`
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `media`,
        path: `${__dirname}/src/markdown/media`
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `pages`,
        path: `${__dirname}/src/markdown/pages`
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `publications`,
        path: `${__dirname}/src/markdown/publications`
      }
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        // CommonMark mode (default: true)
        commonmark: true,
        // Footnotes mode (default: true)
        footnotes: true,
        // Pedantic mode (default: true)
        pedantic: true,
        // GitHub Flavored Markdown mode (default: true)
        gfm: true,
        // Plugins configs
        plugins: []
      }
    },
    {
      resolve: `gatsby-plugin-webfonts`,
      options: {
        fonts: {
          google: [
            {
              family: "Abel",
              variants: ["400"]
            },
            {
              family: "Roboto",
              variants: [
                "300",
                "300italic",
                "400",
                "400italic",
                "500",
                "500italic",
                "700",
                "700italic"
              ]
            },
            {
              family: "Roboto Condensed",
              variants: ["400", "700"]
            },
            {
              family: "Roboto Slab",
              variants: ["400", "700"]
            }
          ]
        },
        usePreload: false
      }
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `GTRI Historical Archive`,
        short_name: `GTRI History`,
        start_url: `/`,
        background_color: `#fff`,
        theme_color: `#A4925A`,
        display: `browser`
      }
    },
    `gatsby-plugin-sass`,
    `gatsby-plugin-sitemap`
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
  mapping: {
    "MarkdownRemark.frontmatter.related_media": `MarkdownRemark.frontmatter.mid`
  },
  // Proxy all requests to /files to the LAB server so we don't need to download
  // any image/pdf/video assets for local development.
  // NOTE: This is only for the `gatsby develop` command.
  // See: https://www.gatsbyjs.com/docs/api-proxy/
  proxy: [
    {
      prefix: "/files",
      url: "https://labhistory.gtri.gatech.edu"
    }
  ]
}
