import React from "react"
import gtriLogo from "../images/logos/gtri-logo.png"

const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <div id="footer" className="footer" aria-label="Footers">
      <div className="superfooter-trigger-wrapper clearfix"></div>
      {/* <!-- Top Footer Gradient Bar --> */}
      <div className="container-fluid footer-top-bar" />
      <div id="gt-footer" className="container-fluid footer-bottom-bar">
        <div className="container pt-3">
          <div className="row">
            <div className="col-sm-12 col-sm-6 col-md-4 col-lg-3 ctn-footer">
              <div className="clearfix" />
              <div id="address_text" className="pt-1">
                <div>
                  <img
                    className="d-md-none mb-4"
                    src={gtriLogo}
                    alt="Georgia Institute of Technology"
                  />
                  <strong>
                    <h2>Georgia Tech Research Institute</h2>
                    <h3>Georgia Institute of Technology</h3>
                  </strong>

                  <div className="gtri-address">
                    250 14th Street, NW
                    <br />
                    Atlanta, GA 30332
                  </div>

                  <a
                    className="gt-phone"
                    href="tel: +14044077400"
                    aria-label="telephone"
                  >
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    404-407-7400
                  </a>

                  <a
                    className="gt-campus-map"
                    href="https://gtri.gatech.edu/location/gtri-headquarters"
                    data-toggle="tooltip"
                    data-placement="top"
                    aria-label="Georgia Tech Research Institute  map"
                  >
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Georgia Tech Research Institute Map
                  </a>

                  <a
                    className="gt-campus-map"
                    href="https://map.gatech.edu/"
                    aria-label="Campus Map"
                  >
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    Campus Map
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-3 pt-1">
              <ul>
                <li>
                  <a
                    href="https://directory.gatech.edu/"
                    aria-label="Directory"
                  >
                    Directory
                  </a>
                </li>
                <li>
                  <a
                    href="https://careers.gtri.gatech.edu"
                    aria-label="Employment"
                  >
                    Employment
                  </a>
                </li>
                <li>
                  <a
                    href="https://gatech.edu/emergency/"
                    aria-label="Emergency Information"
                  >
                    Emergency Information
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-3 pt-1">
              <ul>
                <li>
                  <a
                    href="https://gatech.edu/legal/"
                    aria-label="Legal &amp; Privacy Information"
                  >
                    Legal &amp; Privacy Information
                  </a>
                </li>
                <li>
                  <a
                    href="https://gbi.georgia.gov/human-trafficking-notice"
                    aria-label="Human Trafficking Notice"
                  >
                    Human Trafficking Notice
                  </a>
                </li>
                <li>
                  <a
                    href="https://titleix.gatech.edu/"
                    aria-label="Title IX/Sexual Misconduct"
                  >
                    Title IX/Sexual Misconduct
                  </a>
                </li>
                <li>
                  <a
                    href="https://gatech.edu/accessibility/"
                    aria-label="Accessibility"
                  >
                    Accessibility
                  </a>
                </li>
                <li>
                  <a
                    href="https://gatech.edu/accountability/"
                    aria-label="Accountability"
                  >
                    Accountability
                  </a>
                </li>
                <li>
                  <a
                    href="https://gatech.edu/accreditation/"
                    aria-label="Accreditation"
                  >
                    Accreditation
                  </a>
                </li>
              </ul>
            </div>
            <div
              id="gt-logo-footer"
              className="col-sm-12 col-sm-6 col-md-12 col-lg-3 p-0"
            >
              <div id="copyright" className="d-none d-md-block">
                <img
                  src={gtriLogo}
                  alt="Georgia Institute Research Institute"
                />
                {/* prettier-ignore */}
                <p className="p-1 my-1 float-right"> © {year} Georgia Tech Research Institute </p>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
